import { useOneSignal } from "@onesignal/onesignal-vue3"
import { captureMessage } from "@sentry/vue"
import { watch } from "vue"
import { isCI, isProduction, oneSignalAppId, pushNotifSafariWebId } from "~/config/environment"
import { useUserInfoStore } from "~/stores/userInfo"

// https://developer.mozilla.org/en-US/docs/Web/API/Notification
export enum NotificationPermission {
  SUBSCRIBED = "granted",
  DENIED = "denied",
  PROMPT_REQUIRED = "default",
  UNSUPPORTED = "unsupported",
}

// TEMP: The following is taken from https://github.com/OneSignal/onesignal-vue/blob/fcd5ed4618d1fbbfd09333d4ae7c1bd21a1dbd12/index.ts#L94
// We'll keep using it until we upgrade to OneSignal's new SDK (Vue package v2), which has this
// built in
function isMacOSSafariInIframe(): boolean {
  // Fallback detection for Safari on macOS in an iframe context
  return (
    window.top !== window && // isContextIframe
    navigator.vendor === "Apple Computer, Inc." && // isSafari
    navigator.platform === "MacIntel"
  ) // isMacOS
}

function supportsSafariPush(): boolean {
  return (
    // @ts-expect-error - not our code
    // eslint-disable-next-line unicorn/no-typeof-undefined
    (window.safari && typeof window.safari.pushNotification !== "undefined") ||
    isMacOSSafariInIframe()
  )
}

// Does the browser support the standard Push API
function supportsVapidPush(): boolean {
  return (
    typeof PushSubscriptionOptions !== "undefined" &&
    // Code direct from OneSignal
    // eslint-disable-next-line no-prototype-builtins
    PushSubscriptionOptions.prototype.hasOwnProperty("applicationServerKey")
  )
}

// Checks if the browser supports push notifications by checking if specific
//   classes and properties on them exist
function isPushNotificationsSupported() {
  return supportsVapidPush() || supportsSafariPush()
}
// END TEMP

function pushNotificationState(): NotificationPermission {
  // TODO: Switch back to this with OneSignal SDK upgrade
  // if (!oneSignal.Notifications.isPushSupported()) {
  if (!isPushNotificationsSupported()) {
    // Browser doesn't support notifications
    return NotificationPermission.UNSUPPORTED
  }

  if (Notification.permission === "granted") {
    return NotificationPermission.SUBSCRIBED
  }

  return Notification.permission as NotificationPermission
}

function disablePushNotifications() {
  return (
    isCI ||
    pushNotificationState() === NotificationPermission.UNSUPPORTED ||
    oneSignalAppId === "DISABLED"
  )
}

export const pushNotifications = {
  state: pushNotificationState,
  states: NotificationPermission,

  // subscribes this device to notifications, prompting the user for
  // permission if necessary. We want to do this in context, so will call this
  // only when we do an action that could trigger a notification.
  subscribeAndRequestPermission(): void {
    if (disablePushNotifications()) return

    const oneSignal = useOneSignal()

    const state = pushNotificationState()

    // Prompt the user in-context and subscribe the device. If the user has
    // already granted permission, but the device isn't subscribed, this
    // will subscribe them, and they won't see a prompt.
    // Other states are where they're already subscribed, so no action
    // needed, or they've denied permission, so there's nothing to be done.
    // (In future, maybe we'll show an in-app message to tell them how to
    // grant us permission)
    if (state === NotificationPermission.PROMPT_REQUIRED) {
      oneSignal.showNativePrompt()
    }

    if (state === NotificationPermission.DENIED) {
      // We should figure out the UX for this state, maybe an error message,
      // but deferring that for now. We'll capture the exception and act on it
      // when it comes up in real life.
      captureMessage("Push notifications blocked")
    }
  },
}

function identifyUserForOneSignal(userInfo: User) {
  if (disablePushNotifications()) return

  const oneSignal = useOneSignal()

  if (userInfo.service_signatures?.one_signal) {
    oneSignal.setExternalUserId(userInfo.external_id, userInfo.service_signatures.one_signal)
  } else {
    captureMessage("No onesignal token")
  }
}

function resetOneSignalSession(userInfo: User) {
  if (disablePushNotifications()) return Promise.resolve()

  const oneSignal = useOneSignal()

  // Could do either stop() or clearAllState(). stop would prevent
  // notifications from going to other devices, but clearAllState keeps those
  // while still preventing THIS device from receiving notifications.

  // logout, which actually sets user ID under the hood, so have to pass in the signature
  // again, isn't that weird?
  return oneSignal.setExternalUserId("", userInfo.service_signatures.one_signal)
}

export const usePushNotifications = (): void => {
  if (disablePushNotifications()) return

  const oneSignal = useOneSignal()

  if (!oneSignalAppId) captureMessage("No OneSignal app ID")

  oneSignal.init({
    appId: oneSignalAppId,
    safari_web_id: pushNotifSafariWebId,
    allowLocalhostAsSecureOrigin: !isProduction,
    // Don't send a welcome push notification when they first allow them
    // https://documentation.onesignal.com/docs/web-sdk#init-welcomenotification-parameters
    welcomeNotification: { disable: true },
    notifyButton: { enable: false },
    autoResubscribe: true,
    // For some reason, path is needed as well as the following...
    path: "/_onesignal",
    serviceWorkerPath: "/OneSignalSDKWorker.js",
    serviceWorkerParam: {
      scope: "/_onesignal/",
    },
  })

  const userInfoStore = useUserInfoStore()

  watch(
    () => userInfoStore.data,
    (newUserInfo, oldUserInfo) => {
      if (!oldUserInfo && newUserInfo) {
        identifyUserForOneSignal(newUserInfo)
      } else if (!newUserInfo && oldUserInfo) {
        resetOneSignalSession(oldUserInfo)
      }
    },
  )
}
