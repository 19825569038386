// useFeaturesStore.js
import { defineStore } from "pinia"

export const useFeatureFlagOverridesStore = defineStore("featureFlagOverrides", {
  state: () => ({
    featureFlags: {},
  }),
  actions: {
    enableFeatureFlag(flag: string) {
      this.featureFlags[flag] = true
    },
    disableFeatureFlag(flag: string) {
      this.featureFlags[flag] = false
    },
    setFeatureFlag(flag: string, enabled: boolean) {
      this.featureFlags[flag] = enabled
    },
  },
})
