import { type GraphQLFormattedError } from "graphql"
import { showToast } from "~/lib/notify"

const handleTopLevelError = (graphQLError: GraphQLFormattedError): void => {
  const { message, extensions } = graphQLError

  const errorType = (extensions?.data as { type?: string })?.type
  switch (errorType) {
    case "email_access_required": {
      showToast("Email has to be connected to use this feature.", {
        type: "error",
        action: {
          text: "Connect My Email",
          url: "/getting-started/connect-email",
        },
      })
      break
    }
    case "invalid_refresh_token": {
      showToast("Your connection to cloud storage has expired. Please renew the connection.", {
        type: "error",
        action: {
          text: "Connect Cloud Storage",
          url: "/getting-started/add-file-storage",
        },
      })
      break
    }
    default: {
      showToast(message, { type: "error" })
    }
  }
}

export default handleTopLevelError
