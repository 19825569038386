<script setup lang="ts">
import { container as ModalContainer, config } from "jenesius-vue-modal"
import { RouterView } from "vue-router"
import Toasts from "~/components/Notifications/Toasts.vue"
import ProvideApollo from "~/config/ProvideApollo.vue"
import useCrisp from "~/config/crisp"
import useFeatureFlagOverrides from "~/config/featureFlagOverrides"
import { usePushNotifications } from "~/config/pushNotifications"
import { setupAnalytics } from "~/lib/logUsage"

useCrisp()

setupAnalytics()

useFeatureFlagOverrides()

usePushNotifications()

// Disable animations from the modal lib; we'll manage them ourselves
config({ animation: "none" })
</script>

<template>
  <ProvideApollo>
    <RouterView />
    <ModalContainer />
    <Toasts />
  </ProvideApollo>
</template>
