import * as Sentry from "@sentry/vue"
import * as Spotlight from "@spotlightjs/spotlight"
import { watch, type App } from "vue"
import type { Router } from "vue-router"
import { sentryDsn } from "~/config/environment"
import { useUserInfoStore } from "~/stores/userInfo"

const useSentry = (app: App<Element>, router: Router): void => {
  if (sentryDsn === "DISABLED") return

  Sentry.init({
    app,
    dsn: sentryDsn,

    integrations: [
      // https://docs.sentry.io/platforms/javascript/guides/vue/performance/instrumentation/automatic-instrumentation
      Sentry.browserTracingIntegration({ router }),
      Sentry.extraErrorDataIntegration(),
      Sentry.reportingObserverIntegration({ types: ["crash"] }),
    ],

    // Enable performance tracing
    // 1.0 = 100% of requests will be sampled
    tracesSampleRate: 0.5,

    // ResizeObserver loop limit exceeded is an error generated by chrome
    // when it's not keeping up with all the resizing events being triggered.
    // It bubbles up a lot from v-clamp and is safe to ignore.
    // See more details:
    // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
    // Include stack traces for capturMessages, which can help debug
    // https://docs.sentry.io/platforms/javascript/configuration/options/#attach-stacktrace
    attachStacktrace: true,
  })

  // Setup Sentry Spotlight in dev, must be after Sentry is initialized
  if (import.meta.env.DEV) Spotlight.init()

  const userInfoStore = useUserInfoStore()
  watch(
    () => userInfoStore.data,
    (newUserInfo, oldUserInfo) => {
      if (!oldUserInfo && newUserInfo) {
        const decodedUserId = atob(newUserInfo.id).split("-")[1]
        Sentry.setUser({ email: newUserInfo.email, id: decodedUserId })
      } else if (!newUserInfo && oldUserInfo) {
        // eslint-disable-next-line unicorn/no-null -- external lib that expects null
        Sentry.setUser(null)
      }
    },
  )
}

export default useSentry
