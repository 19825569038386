import type { NavigationGuard, RouteLocationRaw } from "vue-router"
import { getUser } from "~/lib/authRequests"
import { isUserLoggedIn } from "~/lib/componentUtils"
import { showToast } from "~/lib/notify"

// TODO: Can change to using route meta fields to communicate the auth requirements
const DISABLE_AUTH_ROUTES = new Set(["logout", "authenticate"])

// For guest routes, non-logged in users can view, but
// logged-in users are redirected to the home page (equivalent to
// "auth: guest" in nuxt-auth)
const GUEST_ROUTES = new Set(["login", "office-addin-welcome", "office-addin-login"])

const redirectToHomePage = (toRouteName: string): string => {
  if (toRouteName.startsWith("office-")) {
    return "/office-addin/clause-search"
  }
  return "/"
}

const showSessionExpiredToast = (fullPath: string) => {
  if (fullPath !== "/") {
    showToast("Your session has expired. Please sign in to continue where you left off.", {
      type: "info",
      duration: 5000,
    })
  }
}

const generateLoginRedirectQueryAndRedirect = (fullPath: string): RouteLocationRaw => {
  const returnUrl = fullPath
  let query = {}
  if (returnUrl?.length && !["/", "{}"].includes(returnUrl)) query = { returnUrl }
  return { name: "login", query }
}

const authGuard: NavigationGuard = async (to) => {
  const toRouteName = to.name as string

  if (DISABLE_AUTH_ROUTES.has(toRouteName)) {
    return true
  }

  // for existing sessions, just attempt to grab the user_info from the backend;
  // this also acts a check that the session is not expired
  // When their session has expired, we redirect them to /login even though the user info is set...
  // so when doing that, force fetching (see config/apollo for the showToast)
  if (!isUserLoggedIn() || to.path?.startsWith("/login")) {
    await getUser()
  }

  // special behaviour for login pages: non-logged in users can view, but
  // logged-in users are redirected to the home page (equivalent to
  // "auth: guest" in nuxt-auth)
  if (GUEST_ROUTES.has(toRouteName)) {
    if (isUserLoggedIn()) {
      return redirectToHomePage(toRouteName)
    }
    return true
  }

  // If user is authenticated, then no action -- they get to see the page :)
  if (isUserLoggedIn()) return true

  showSessionExpiredToast(to.fullPath)

  return generateLoginRedirectQueryAndRedirect(to.fullPath)
}

export default authGuard
