<script lang="ts" setup>
import { DefaultApolloClient } from "@vue/apollo-composable"
import { provide } from "vue"
import { apolloClient } from "~/config/apollo"

// Apollo composition API must be installed in setup; options API setup is in main.ts
provide(DefaultApolloClient, apolloClient)
</script>

<template>
  <slot />
</template>
