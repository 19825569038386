import { useFeatureFlagOverridesStore } from "~/stores/featureFlagOverrides"

const useFeatureFlagOverrides = (): void => {
  const store = useFeatureFlagOverridesStore()

  // These methods need to go on the window object so Cypress can call them
  window.enableFeatureFlagLocally = (flag: string) => {
    store.enableFeatureFlag(flag)
  }

  window.disableFeatureFlagLocally = (flag: string) => {
    store.disableFeatureFlag(flag)
  }
}

export default useFeatureFlagOverrides
