import { InMemoryCache } from "@apollo/client/core"

// Convert date fields to JS Date objects for easier sorting, display, etc:
// https://www.apollographql.com/docs/react/caching/cache-configuration/#typepolicies
const inMemoryCacheOptions = {
  typePolicies: {
    Attachment: {
      fields: {
        sentAt: {
          read(sentAt: string) {
            return new Date(sentAt)
          },
        },
      },
    },
    Record: {
      fields: {
        lastActivityTime: {
          read(lastActivityTime: string) {
            return new Date(lastActivityTime)
          },
        },
        receivedAt: {
          read(receivedAtTime: string) {
            if (receivedAtTime) {
              return new Date(receivedAtTime)
            }
          },
        },
      },
    },
    Conversation: {
      fields: {
        sentAt: {
          read(sentAt: string) {
            return new Date(sentAt)
          },
        },
        lastMessageTimestamp: {
          read(sentAt: string) {
            return new Date(sentAt)
          },
        },
      },
    },
    Email: {
      fields: {
        sentAt: {
          read(sentAt: string) {
            return new Date(sentAt)
          },
        },
      },
    },
    FileLocation: {
      fields: {
        receivedAt: {
          read(receivedAtTime: string) {
            if (receivedAtTime) {
              return new Date(receivedAtTime)
            }
            return undefined
          },
        },
      },
    },
    FileRecord: {
      fields: {
        receivedAt: {
          read(receivedAtTime: string) {
            if (receivedAtTime) {
              return new Date(receivedAtTime)
            }
            return undefined
          },
        },
      },
    },
  },
}

const cache = new InMemoryCache(inMemoryCacheOptions)

export default cache
