import { defineStore } from "pinia"

export const useUserInfoStore = defineStore("userInfo", {
  state: () => ({
    data: undefined as User | undefined,
    getUserEndpointFailed: false,
  }),
  actions: {
    updateUser(updatedUser: User | undefined) {
      this.data = updatedUser
    },
    updateGetUserEndpointFailed(failed: boolean) {
      this.getUserEndpointFailed = failed
    },
    updateOnboarding(step: string) {
      this.data.onboarding[step] = true
    },
    updateCloudStorageStatus(status: string) {
      this.data.permissions.cloud_storage = status
    },
  },
})
