import { options } from "floating-vue"

// Docs are https://floating-vue.starpad.dev/guide/config

// Skip delay & CSS transitions when another popper is shown, so that the popper appear to instanly move to the new position.
options.instantMove = true

// TODO: Should probably decrease this to 0, so the popover disappears right awaay, but some of our
// app seems to depend on this for now. The default was changed here:
// https://github.com/Akryum/floating-vue/commit/0699bf8f2f792f8ff0989e8bcef5f98f9c50b6c3
options.disposeTimeout = 5000

export { default } from "floating-vue"

export * from "floating-vue"
