import { notify } from "notiwind"
import { logUsage } from "~/lib/logUsage"

type CloseFunction = () => void

export interface ToastAction {
  onClick?: (e: MouseEvent, close: CloseFunction) => void
  text: string
  url?: string
}

interface Toast {
  type?: string
  title?: string
  action?: ToastAction | null
  duration?: number
}

export const showToast = (message: string, options: Toast = {}): void => {
  // TODO: Most of the below logic can be centralized to the Toast action itself
  const { type = "success", action, title }: Toast = options

  let { duration } = options
  if (type === "error") {
    // We want errors to stick around long enough to be copied/screenshotted, but not so long that
    // they're permanent -- especially because some errors don't impact the user at all
    duration = 60_000
  } else if (options.action) {
    // In order to give users time to act on action toasts
    // time is set to 5 seconds, non-action ones are set to 3
    // See "Move from soft deletion to hard deletion" design doc for more info
    duration ||= 5000
  } else {
    duration ||= 3000
  }

  notify(
    {
      group: "toasts",
      text: message,
      action,
      type,
      duration,
      title,
    },
    duration,
  )

  if (type !== "success" && type !== "info") {
    // Always use event: 'error' because that's what the backend
    // uses to trigger displaying the error in Slack, that means it will
    // show the messages as the user saw an error even if it was a
    // different toast type
    logUsage({ event: "error", errorMessage: message })
  }
}

export default showToast
